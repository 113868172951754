<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="list_page3">
            <div class="form_wrap">
              <div class="form">
                <ul class="form_ul">
                  <li class="form_li">
                    <div class="form_ttl">빌라 선택</div>
                    <div class="form_in">
                      <div class="inline">
                        <label>
                          <select
                            name="build"
                            class="select"
                            v-model="form.buildingNo"
                            :disabled="userData.status === 2"
                          >
                            <option value="">선택해주세요</option>
                            <option
                              v-for="item in buildingList"
                              :key="item.buildingNo"
                              :value="item.buildingNo"
                              >{{ item.name }}</option
                            >
                          </select>
                        </label>
                      </div>
                      <div
                        class="hint"
                        :class="{ on: valid.form.buildingNo.$error }"
                      >
                        <p>빌라를 선택해 주세요.</p>
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">세대주 명</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input type="text" v-model="form.owner" />
                        </label>
                      </div>
                      <div
                        class="hint"
                        :class="{ on: valid.form.owner.$error }"
                      >
                        <p>세대주 명을 입력해 주세요.</p>
                      </div>
                    </div>
                  </li>
                  <li class="form_li">
                    <div class="form_ttl">동ㆍ호수</div>
                    <div class="form_in">
                      <div class="inline">
                        <label class="input">
                          <input type="text" v-model="form.unit" />
                        </label>
                      </div>
                      <div class="hint" :class="{ on: valid.form.unit.$error }">
                        <p>동ㆍ호수를 입력해 주세요.</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="notice_box sky" v-show="userData.status === 1">
            <div class="notice grey">
              <!--grey: 확인중 orange: 거절 red:확인-->
              <p class="txt">
                관리자가 확인 중에 있습니다.<br />변경사항이 있으면 정보 변경을
                신청해 주세요.
              </p>
            </div>
          </div>
          <div class="notice_box sky" v-show="userData.status === 2">
            <div class="notice red">
              <p class="txt">
                등록 되었습니다.<br />정보 수정이 필요한 경우 민원 접수를 이용해
                주세요.
              </p>
            </div>
          </div>
          <div class="notice_box sky" v-show="userData.status === 3">
            <div class="notice orange">
              <p class="txt">
                입력된 정보와 동일한 세대주가 없습니다.<br />정보 변경으로 다시
                신청해 주세요.
              </p>
            </div>
          </div>
        </div>
        <div class="page_btn" v-show="userData.status !== 2">
          <div class="btn_wrap">
            <button class="btn on" @click="fnSave">정보 변경</button>
            <!--입력되면 on-->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { getCurrentInstance, reactive, toRefs } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

const buildState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const { dispatch } = useStore()
  const store = useStore()

  const userData = store.getters['user/getData']

  const state = reactive({
    buildingList: [],
    form: {
      buildingNo: '',
      owner: '',
      unit: ''
    }
  })

  const fetchBuildingList = async () => {
    const res = await proxy.$BuildingSvc.fetchBuildingListByName()
    if (res.code === 1) {
      state.buildingList = res.entity.list
    }
  }

  const fetchUserData = async () => {
    const res = await proxy.$UserSvc.fetchUserRequestData()
    if (res.code === 1) {
      state.form = res.entity
    }
  }

  /** validations **/
  const rules = {
    form: {
      buildingNo: {
        required
      },
      owner: {
        required
      },
      unit: {
        required
      }
    }
  }

  const valid = useVuelidate(rules, state)

  const fnSave = async () => {
    valid.value.$touch()
    if (valid.value.$invalid) return
    const res = await proxy.$UserSvc.updateUserBuildingInfo(state.form)
    if (res.code === 1) {
      alert('등록되었습니다.')
      await proxy.$SignSvc.postMapChangeToken()
      router.push({ path: '/main', query: { isRoot: 1 } })
    }
  }

  const fnModalAlert = async () => {
    const payload = {}
    payload.component = proxy.$modalAlertNames.CONFIRM
    payload.layout = {
      title: '확인',
      contents: 'test'
    }
    payload.data = {}
    payload.callBackCancel = proxy => {
      proxy.$emit('close')
    }
    await dispatch('layout/pushModalAlertComponent', payload)
  }
  fetchBuildingList()
  fetchUserData()
  return {
    fetchBuildingList,
    fetchUserData,
    ...toRefs(state),
    userData,
    fnSave,
    fnModalAlert,
    valid
  }
}

export default {
  name: 'menu-building-register',
  description: '빌라 정보 등록 페이지',
  setup() {
    return {
      ...buildState()
    }
  }
}
</script>

<style scoped></style>
